@import "github-fork-ribbon-css/gh-fork-ribbon.css";

* {
  font-family: Lucida Console, monospace;
  color: #FFF;
}

body {
  background: black;
}
